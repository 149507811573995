/* This config path is different from the website. This config path point to src > scss > amp >config */
@use "../config/" as *;

.ct,
.ctf .ctx,
.ctl,
.ctm,
.cts {
  width: 100%;
  @extend %px-2;
  @extend %mx-auto;
}

.rw {
  flex-wrap: wrap;
  @extend %mr-2-neg;
  @extend %ml-2-neg;
  @extend %flex;
  & > *{
    position: relative;
    width: 100%;
  }
}