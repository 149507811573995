@use "../config/" as *;

.waf-statsdetail {
    @extend %p-4;
    .waf-head {
        @extend %d-none;
    }
    .card {
        &-item {
            background: linear-gradient(92.5deg, color(secondary, 800) -0.01%, color(secondary,1000) 133.57%);
            @extend %half-radius, %mb-4;
        }
        &-body {
            @extend %pure-white-900-bg-1;
        }
        &-head {
            @extend %flex-n-c, %flex-wrap, %p-4, %gap-4;
            .card-points-list {
                @extend %d-none;
            }
        }
        &-thumbnail {
            width: rem(79);
            height: rem(79);
            aspect-ratio: 1;
            border: .2rem solid color(primary,300);
            @extend %circle-radius, %hidden, %neutral-300-bg;
        }
        &-position {
            @extend %font-20-pb, %pure-white-900-3, %mb-2;
        }
        &-name {
            max-width: 20rem;
            @extend %font-20-pb, %flex, %flex-wrap, %gap-1, %my-0;
            .name {
                @extend %pure-white-900, %capitalize;
            }
        }
        &-points-list {
            align-items: stretch;
            @extend %flex-c-c, %flex-wrap;
            &:not(:first-child) {
                .team-image {
                    @extend %d-none;
                }
            }
        }
        &-points {
            border-bottom: .1rem solid color(pure-white,900);
            @extend %flex-column-c-c, %gap-2, %w-50, %py-4, %px-1, %relative;
            .number {
                @extend %pure-white-900, %font-16-pb, %text-center;
            }
            .label {
                @extend %pure-white-900-6, %uppercase, %font-10-pr;
            }
            &:not(:last-child):nth-child(odd)::after {
                content: '';
                width: .1rem;
                height: 50%;
                right: 0;
                @extend %pure-white-900-bg-1, %pos-y-center;
            }
        }
    }
    .player-image {
        object-fit: cover;
        object-position: top;
        @extend %h-100;
    }
    .country-flag {
        .card-thumbnail {
            height: auto;
            aspect-ratio: 3 / 2;
            border: 0.1rem solid color(pure-white, 900);
            @extend %half-radius;
        }
        .player-image {
            object-position: center;
        }
    }
    .table {
        overflow-x: auto;
        border: .1rem solid color(neutral, 100);
        .text {
            @extend %neutral-800, %font-10-pm;
        }
        &-head {
            @extend %neutral-1000-bg;
            .text {
                @extend %font-10-pb, %uppercase;
            }
            .table-data {
                @extend %neutral-1000-bg;
            }
        }
        &-row {
            border-bottom: 0;
            &:not(:last-child) .table-data {
                border-bottom: .1rem solid color(neutral,100);
            }
        }
        &-body {
            .table-data {
                height: rem(65);
                &.highlight {
                    @extend %primary-50-bg;
                    .text {
                        @extend %font-10-pb;
                    }
                }
            }
        }
        &-left,&-right {
            width: unset;
        }
        &-left .table-body .text {
            @extend %font-12-pb;
        }
        &-right {
            overflow: unset;
        }
        &-data {
            min-width: rem(100);
            @extend %flex-c-c, %px-2;
            &.table-data-player {
                justify-content: flex-start;
            }
            &.table-data-team-info {
                min-width: rem(120);
            }
            &.table-data-venue-name,&.table-data-vs-team-name,&.table-data-player {
                min-width: rem(130);
            }
            &-player-info {
                justify-content: flex-start;
                @extend %px-1;
            }
        }
        &-data-position {
            min-width: rem(42);
            @extend %font-12-pb;
        }
        &-data-player {
            @extend %pl-2;
            .player-image {
                @extend %d-none;
            }
            .table-data-name,
            .team-data-team {
                @extend %neutral-900, %font-12-pb, %text-left, %my-0, %pb-0;
            }
            .table-data-text {
                line-height: 1;
                @extend %text-left;
            }
        }
        .no-data-wrap {
            border: .1rem solid color(secondary,900);
            @extend %p-3, %text-center, %uppercase, %full-radius, %secondary-900, %font-16-pb;
        }
    }
    .btn-more {
        border: 0.1rem solid color(secondary,800);
        height: rem(34);
        width: max-content;
        @extend %font-12-pb, %secondary-800, %half-radius, %capitalize, %flex-c-c, %p-2, %my-4, %gap-2, %mx-auto;
        &:after {
            width: 1.4rem;
            font-weight: 400;
            @include icon(drop-arrow, 14);
        }
        .btn-text {
            @extend %font-12-pb, %uppercase;
        }
    }
}