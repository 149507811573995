@use "../config/" as *;

.waf-standings {
    .table-wrap {
        @extend %p-4;
        @extend %half-radius;
        @extend %pure-white-900-bg;
        
    }
    .table {
        border: unset;
        .text {
            @extend %neutral-800;
        }
        &-title {
            font-weight: 700;
            @extend %mb-4, %uppercase;
        }
    }
    .team {
        &-information {
            align-items: center;
            @extend %flex;
            @extend %gap-3;

        }
        &-image {
            width: 1.8rem;
            height: 1.12rem;
            @extend %neutral-50-bg;
        }
    }
}