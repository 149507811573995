@use "../config/" as *;

%horizontal-line {
	height: 0.1rem;
	background: linear-gradient(90deg,
			color(pure-white, 900) 0%,
			color(pure-white, 900, 2) 47%,
			color(pure-white, 900, 0) 100%);
	@extend %absolute;
	@extend %w-100;
}

%vertical-line {
	background: linear-gradient(360deg,
			color(pure-white, 900, 0) 0%,
			color(pure-white, 900, 2) 47%,
			color(pure-white, 900, 0) 100%);
	width: 0.1rem;
	@extend %absolute;
	@extend %h-100;
}

@each $weather in $weathers {
	.#{"#{$weather}"} {
 .icon {
		@include background(null,
			"icons/#{$weather}.png",
			center / contain no-repeat);
	}
}
}

.masthead-section {
	--_team-logo: 2.5rem;
	// background-color: color(primary,900);

	.card {
		&-item {
			@include separator(60%, y, 0.1rem);
			@extend %pure-white-900;
			.status {
				height: 1.8rem;
				display: inline-flex;
				align-items: center;
				@extend %secondary-200-bg;
				@extend %rounded-radius;
				@extend %p-0-3;
				@extend %pure-white-900;
				@extend %font-10-pb;
				@extend %uppercase;
				@extend %gap-1;
			}

			&.live {
				.status {
					@extend %success-700-bg;

					&::after {
						content: "";
						aspect-ratio: 1 / 1;
						height: 1.2rem;
						flex-shrink: 0;
						@include background(null, "svg/live-white.svg", center/cover);
						@extend %d-block;
					}
				}
			}

			&.upcoming {
				.card-head {
					@extend %pb-10;
				}

				.team-score,
				.team-a::after {
					@extend %d-none;
				}

				.form-guide,
				.form-guide-wrapper {
					@extend %d-block;
				}

				.card-head {
					&::after {
						bottom: 2rem;
					}
				}
			}

			&.recent {
				.status {
					@extend %error-800-bg;
				}
			}
		}

		&-wrap {
			background: linear-gradient(92.5deg,
			hsl(269 82% 9%) -0.01%,
			hsl(269 83% 25%) 133.57%);
		}

		&-head-top {
			@include grid(2, 2rem);
			@extend %secondary-50-bg;
			@extend %p-4;
			@extend %relative;

			&::after {
				content: "";
				height: 0.1rem;
				bottom: 50%;
				background: linear-gradient(90deg,
						color(neutral, 900, 0) 0%,
						color(neutral, 900, 2) 47%,
						color(neutral, 900, 0) 100%);
				@extend %absolute;
				@extend %w-100;
			}

			.match-status {
				grid-area: 1 / 1 / 2 / 2;
			}

			.match-info {
				grid-area: 2 / 1 / 3 / 3;
				@extend %neutral-800;
				@extend %font-12-pb;

				// @extend %capitalize;
				.title {
					@extend %text-left;
					@extend %neutral-800;
					@extend %font-16-pb;
				}
			}

			

			.share-page {
				grid-area: 1 / 2 / 2 / 3;
				@extend %flex-fe-c;
			}

			.icon-b-share::before {
				content: "";
				@include icon(p-share, 14);
				@extend %neutral-900;
			}
			.share-icon{
				@extend %flex;
				&:before{
					@extend %d-none;
				}
			}
		}

		&-head {
			@extend %relative;

			&::after {
				content: "";
				@extend %horizontal-line;
			}
		}

		&-head-bottom {
			@extend %p-4;
		}

		&-body {
			@extend %px-4;
			@extend %relative;

			&::after {
				content: "";
				bottom: 0;
				@extend %d-none;
				@extend %horizontal-line;
			}

			.score {
				@extend %font-16-pb;
			}
		}

		&-footer {
			@extend %text-center;
			@extend %py-4;
			@extend %relative;

			&::after {
				content: "";
				@extend %d-none;
				@extend %horizontal-line;
			}

			.text {
				@extend %font-12-pb;
				@extend %capitalize;
			}
		}
	}

	.team {
		@extend %flex-n-c;
		@extend %w-100;
		@extend %relative;
		@extend %py-4;

		&::after {
			content: "";
			bottom: 0;
			@extend %horizontal-line;
		}

		&::before {
			content: "";
			@extend %d-none;
			@extend %vertical-line;
		}

		&.team-won {
			.team-score:last-child .score {
				@extend %primary-300;
			}
		}

		&.team-current {
			.team-score:last-child .score {
				@extend %primary-300;
			}

			.team-name::after {
				@include icon(batter, 12);
			}

			.team-img {
				outline: 0.1rem solid color(primary, 300);
				border-radius: 0.2rem;
			}
		}

		&-name {
			.name {
				@extend %font-11-pb;
			}
		}

		&-img {
			height: 1.5rem;
			width: var(--_team-logo);
			@extend %neutral-50-bg;
			@extend %half-radius;

			img {
				height: 100%;
				width: 100%;
			}
		}

		&-status {
			@extend %pb-2;
		}

		&-info {
			@extend %flex-n-c;
			@extend %gap-2;
		}

		&-data-wrap {
			@extend %ml-auto;
			@extend %text-right;

			.team-name {
				@extend %d-none;
			}
		}

		&-score {
			.team-extra {
				@extend %font-10-pr;
				@extend %pure-white-900-5;
			}
		}

		&-extra {
			gap: 0.2rem;
			@extend %flex;

			.overs {
				order: 1;
			}
		}
	}

	.match {
		&-time {
			@extend %d-inline;
			@extend %font-12-pm;

			.date::after {
				content: "|";
				@extend %mx-1;
			}
		}

		&-weather {
			@extend %gap-2;
			@extend %flex-n-c;
			@extend %mt-3;
			@extend %font-12-pb;

			.icon {
				width: 2rem;
				height: 2rem;
				@extend %flex-c-c;
			}
		}

		&-venue {
			@extend %pure-white-900;
			@extend %font-12-pm;
			@extend %capitalize;
			@extend %d-inline;
			@extend %relative;

			&:after {
				content: "|";
				@extend %mx-1;
			}
		}
	}

	.form-guide {
		@extend %d-none;
		@extend %ml-auto;

		&-wrapper {
			z-index: var(--z-default);
			@include position(-1.2rem, 0.8rem, null, null, absolute);
			@extend %d-none;

			.title {
				cursor: pointer;
				@extend %font-12-pb;
				@extend %flex-n-c;
				@extend %gap-2;
				@extend %pure-white-900;

				&:after {
					font-weight: 400;
					@include icon(p-info, 14);
				}

				&:hover {
					&~.tooltip {
						@extend %flex-c-c;
					}
				}
			}

			.tooltip {
				min-height: 3.2rem;
				min-width: 15rem;
				border-radius: 0.2rem;
				@include position(-4rem, 0, null, null, absolute);
				@extend %text-center;
				@extend %d-none;
				@extend %gap-1;
				@extend %primary-100-bg;
				@extend %uppercase;
				@extend %font-10-pb;
				@extend %neutral-800;

				&:before {
					@include icon(arrow-forward);
				}

				&-data::after {
					content: "";
					height: 1rem;
					aspect-ratio: 1 / 1;
					transform: rotate(45deg);
					@include position(null, 0.3rem, -0.3rem, null, absolute);
					@extend %primary-100-bg;
				}
			}
			.shortname{
				@extend %d-none;
			}
			.name{
				@extend %flex;
			}
			.team,.title{
				@extend %p-0;
			}
		}

		&-listing {
			list-style: none;
			@extend %flex;
			@extend %gap-3;
			@extend %pl-3;
		}

		&-item {
			height: rem(24);
			width: rem(24);
			@extend %half-radius;
			@extend %flex-c-c;
			@extend %pure-white-900;
			@extend %success-800-bg;

			&.lost {
				@extend %error-800-bg;
			}
			p{
				@extend %pure-white-900;
			}
		}
	}

	.innings-info {
		@extend %secondary-800-bg;
		@extend %p-4;

		.head {
			@extend %mb-4;
			@extend %font-12-pr;
			@extend %capitalize;
		}

		.player {
			&-details {
				&:not(:last-child) .player-data {
					@extend %mb-3;
				}
			}

			&-data {
				@extend %flex-column;
				@extend %gap-3;
			}

			&-thumbnail {
				aspect-ratio: 1 / 1;
				height: 2rem;
				flex-shrink: 0;
				@extend %secondary-200-bg;
				@extend %circle-radius;
				@extend %hidden;

				img {
					@extend %h-100;
					@extend %w-100;
				}
			}

			&-info {
				@extend %p-2;
				@extend %secondary-500-bg;
				@extend %flex-n-c;
				@extend %gap-2;
				@extend %half-radius;
				@extend %relative;
				@extend %hidden;

				&.active {
					&:after {
						content: "";
						left: 0;
						width: 0.18rem;
						@extend %absolute;
						@extend %h-100;
						@extend %primary-300-bg;
					}

					.player-thumbnail {
						outline: 0.1rem solid color(primary, 300);
					}
				}
			}

			&-info-content {
				@extend %gap-1;
				@extend %flex-n-c;
				@extend %w-100;
			}

			&-name {
				column-gap: var(--space-1);
				@extend %pure-white-900;
				@extend %flex-wrap;
				@extend %flex;

				.name {
					@extend %capitalize;
					@extend %font-10-pr;
				}
			}

			&-score {
				flex-shrink: 0;
				@extend %font-12-pr;
				@extend %ml-auto;
				@extend %flex-c-c;
			}
		}
	}

	.score-data {
		@extend %pure-white-900-bg;

		.tab-name {
			border-radius: 0;
			@extend %m-0;
			@extend %flex-c-c;
			@extend %p-2;
			@extend %secondary-50-bg;
		}
		.tabs {
			@extend %p-0;
		}
		.tabs span {
			@extend %font-12-pm;
			@extend %primary-1000;
		}
		.tabs .label {
			@extend %uppercase;
		}

		.over {
			&-list {
				@extend %px-4;
				@extend %flex-c-c;
				@extend %gap-1;
			}

			&-item {
				position: relative;
				height: 1.5rem;
				min-width: 1.5rem;
				border: 0.1rem solid color(neutral, 600);
				
				@extend %uppercase;
				@extend %flex-c-c;
				@extend %p-1-1;
				@extend %half-radius;

				span {
					@extend %neutral-600;
				}

				&.four {
					border-color: color(secondary, 1000);
					@extend %secondary-1000-bg;

					.text {
						@extend %pure-white-900;
					}
				}

				&.six {
					border-color: color(primary, 800);
					@extend %primary-800-bg;

					.text {
						@extend %pure-white-900;
					}
				}

				&.wicket {
					border-color: color(error, 800);
					@extend %error-800-bg;

					.text {
						@extend %pure-white-900;
					}
				}

				.extra-text {
					width: .9rem;
					aspect-ratio: 1/1;
					@include position(-.5rem, -0.5rem, null, null);
					@extend %flex-c-c;
					@extend %circle-radius;
					@extend %pure-white-900;
					@extend %neutral-800-bg;
					@extend %font-8-pm;
				}

				.text {
					@extend %font-10-psb;
				}
			}

			&-label {
				@extend %neutral-600;
				@extend %font-10-pr;
				@extend %capitalize;
			}
		}

		.card {
			&-list {
				@extend %scroll;
				@extend %flex;
			}

			&-item {
				cursor: pointer;
			}

			&-wrapper {
				@extend %flex-column-n-c;
				@extend %gap-2;
				@extend %py-4;
			}
		}

		// .swiper {

		// 	&-button-prev,
		// 	&-button-next {
		// 		height: 70%;
		// 		border-radius: unset;
		// 		border: 0;
		// 		@extend %pure-white-900-bg;
		// 		@extend %p-2;
		// 		@extend %flex-c-c;
		// 		@extend %pos-y-center;
		// 	}

		// 	&-button-prev {
		// 		border-right: 0.1rem solid color(neutral, 900, 1);
		// 		left: 0;

		// 		&::after {
		// 			@include icon(chevron-left, 12);
		// 		}
		// 	}

		// 	&-button-next {
		// 		border-left: 0.1rem solid color(neutral, 900, 1);
		// 		right: 0;

		// 		&::after {
		// 			@include icon(chevron-right, 12);
		// 		}
		// 	}
		// }
	}

	.end-match-footer {
		@extend %secondary-900-bg;
		@extend %primary-300;
		@extend %font-12-pr;
		@extend %capitalize;
		@extend %p-1-2;
		@extend %half-radius;
		@extend %d-inline-block;
		@extend %mt-2;

		.text {
			
			font-weight: 400;
			@extend %pure-white-900;
		}
	}

	.super-over {
		.card-body {
			.score {
				@extend %pure-white-900-5;
				@extend %font-12-pb;
			}
		}

		.team-score {
			flex-direction: row-reverse;
			@extend %gap-2;
			@extend %flex-n-c;

			&:not(:last-child) {
				@extend %mb-1;
			}
		}

		.super-over-text {
			@extend %d-block;
			@extend %capitalize;
		}
	}

	.super-over-text,
	.shortname {
		display: none;
	}

	.social-share-wrap {
		top: 1.5rem;
		right: 1.5rem;
		@extend %d-none;
	}

	.text-vs {
		height: 3rem;
		aspect-ratio: 1 / 1;
		@extend %d-none;
		@extend %secondary-600-bg;
		@extend %circle-radius;
	}
}

.swiper-button-prev,
.swiper-button-next {
	@extend %d-none;
}