@use "../config/" as *;
.waf-showcase {
    .article {
        &-list {
            @extend %gap-6,%flex-column;
        }
        &-wrap{
            @extend %flex,%gap-2;
        }
        &-thumbnail{
            width: 10rem;
        }
        &-item {
            overflow: visible;
            @extend %relative;
            &:not(:last-child) {
                &:after {
                    content: '';
                    height: .1rem;
                    @include position(null, null, var(--space-3-neg), 0);
                    @extend %neutral-100-bg,%w-100;
                }
            }
        }
        &-title {
            line-height: 1.4rem;
            height: auto;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @extend %mt-4,%neutral-800;
        }
        &-meta {
            .meta-category {
                @include position-combo(tl);
                @extend %pr-0;
            }
        }
        &-content {
            position: relative;
            width: calc(100% - 10rem);
            > a {
                @extend %neutral-800;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &-description {
            @extend %d-none;
            .text {
                font-size: 1.4rem;
                line-height: 2rem;
            }
        }

    }
    .meta{
        font-size: .6rem;
    }
    img{
        @extend %w-100,%h-100;
        }
    .img-box{
        @extend %m-0;
    }
}