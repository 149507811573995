@use "../config/" as *;

.waf-quote {
    @extend %p-12-4, %primary-300-bg;
    .quote-wrapper {
        @extend %text-center;
    }
    .title {
        isolation: isolate;
        @extend %relative;
        @extend %neutral-800;
        @extend %font-24-pb;
        @extend %pt-0;
        @extend %mb-7;
        &::before,
        &::after {
            content: '';
            width: 4.3rem;
            height: 3.7rem;
            z-index: $z-quotes;
            @include background(null, "icons/quote.png", center / contain no-repeat);
        }
        &::before {
            @include position(-2rem, null, null, 0);
        }
        &::after {
            rotate: 360deg;
            transform: scaleX(-1);
            @include position(null, null, -3rem, null);
        }
    }
    .text {
        font-size: 1rem;
        @extend %neutral-800;
    }
}