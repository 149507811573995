@use "amp-variables" as *;

@font-face {
  font-family: "waf-font-icon";
  src: url("/static-assets/build/fonts/waf-font-icon.eot?v=#{$font-version}");
  src: url("/static-assets/build/fonts/waf-font-icon.eot?v=#{$font-version}#iefix")
      format("embedded-opentype"),
    url("/static-assets/build/fonts/waf-font-icon.woff2?v=#{$font-version}")
      format("woff2"),
    url("/static-assets/build/fonts/waf-font-icon.woff?v=#{$font-version}")
      format("woff"),
    url("/static-assets/build/fonts/waf-font-icon.ttf?v=#{$font-version}")
      format("truetype"),
    url("/static-assets/build/fonts/waf-font-icon.svg?v=#{$font-version}#waf-font-icon")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"] {
  font-size: 0;
  font-style: normal;
  color: #fff;
}

[class^="icon-"]::before {
  font-family: $font-icon;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
