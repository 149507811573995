@use "amp-variables" as *;
@use "amp-map" as *;
@use "amp-mixins" as *;


@for $size from 8 through 60 {
  $font-size: #{$size * 0.0625rem};

  // Base font size placeholder
  %font-#{$size} {
    font-size: $font-size;
  }

  // Generate variations based on font details
  @each $font-family, $font-variation in $font-detail {
    @each $font-alias, $font-type in $font-variation {
      $font-weight: map-get($font-type, "weight");
      $font-family: map-get($font-type, "family");

      %font-#{$size}-#{$font-alias} {
        font: #{$font-weight} #{$font-size} #{$font-family};
      }
    }
  }
}

%font-0 {
  font-size: 0;
}

// HOW TO USE
// @extend %font-12;
// @extend %font-12-pr;
// @extend %font-16-pb;
// @extend %font-20-pm;

//colors start
@each $category, $shades in $colors {
  @each $shade, $hsl-value in $shades {
    $hue: nth($hsl-value, 1);
    $saturation: nth($hsl-value, 2);
    $lightness: nth($hsl-value, 3);

    // Base color and background color placeholders
    %#{$category}-#{$shade} {
      color: hsl($hue, $saturation, $lightness);
    }

    %#{$category}-#{$shade}-bg {
      background-color: hsl($hue, $saturation, $lightness);
    }

    // Generate opacity variations
    @for $opacity from 1 through 10 {
      $alpha: $opacity * 0.1;

      %#{$category}-#{$shade}-#{$opacity} {
        color: hsla($hue, $saturation, $lightness, $alpha);
      }

      %#{$category}-#{$shade}-bg-#{$opacity} {
        background-color: hsla($hue, $saturation, $lightness, $alpha);
      }
    }
  }
}


%transparent-bg {
  background-color: transparent;
}

// HOW TO USE
// @extend %neutral-100;
// @extend %neutral-100-9;
// @extend %neutral-100-bg;
// @extend %neutral-100-bg-9;
// convention used t=top,l=left,b=bottom,r=right,y=block[top-bottom],x=inline[left-right],m=margin,p=padding
%relative {
  position: relative;
}

%absolute {
  position: absolute;
}

%fixed {
  position: fixed;
}

//how to use
// @extend %pos-center;
//@extend %pos-tl-fix;

@each $pos in $positions {
  %pos-#{$pos} {
    @include position-combo($pos);
  }

  %pos-#{$pos}-fix {
    @include position-combo($pos, null, null, fixed);
  }
}

// border-radius starts
@each $name, $value in $border-radii {
  %#{$name} {
    border-radius: $value;
  }
}

// border-radius ends
// convention used t=top,l=left,b=bottom,r=right,y=block[top-bottom],x=inline[left-right],m=margin,p=padding

// spacing starts
@each $direction, $dir-value in $directions {
  @for $space from 0 through $max-space-count {
    $computed-space: $space * $base-space;
    $negative-space: $computed-space * -1;

    // Margin and padding styles for positive and negative values
    %m#{$direction}-#{$space} {
      margin#{$dir-value}: $computed-space;
    }

    %p#{$direction}-#{$space} {
      padding#{$dir-value}: $computed-space;
    }

    %m#{$direction}-#{$space}-neg {
      margin#{$dir-value}: $negative-space;
    }

    %p#{$direction}-#{$space}-neg {
      padding#{$dir-value}: $negative-space;
    }
  }

  // Auto margin and padding
  %m#{$direction}-auto {
    margin#{$dir-value}: auto;
  }

  %p#{$direction}-auto {
    padding#{$dir-value}: auto;
  }
}

// Margin and padding for all combinations of $i and $j
@for $i from 0 through $max-space-count {
  @for $j from 0 through $max-space-count {
    $computed-space-i: $i * $base-space;
    $computed-space-j: $j * $base-space;

    $i-rem: $computed-space-i;
    $j-rem: $computed-space-j;

    %m-#{$i}-#{$j} {
      margin: $i-rem $j-rem;
    }

    %p-#{$i}-#{$j} {
      padding: $i-rem $j-rem;
    }
  }
}

// Generic margin, padding, and gap for space values (positive and negative)
@for $space from 0 through $max-space-count {
  $space-value: $space * $base-space;
  $negative-space-value: $space-value * -1;

  // Margin, padding, and gap for positive space
  %m-#{$space} {
    margin: $space-value;
  }

  %p-#{$space} {
    padding: $space-value;
  }

  %gap-#{$space} {
    gap: $space-value;
  }

  // Margin, padding, and gap for negative space
  %m-#{$space}-neg {
    margin: $negative-space-value;
  }

  %p-#{$space}-neg {
    padding: $negative-space-value;
  }

  %gap-#{$space}-neg {
    gap: $negative-space-value;
  }
}

// Auto margin and padding
%m-auto {
  margin: auto;
}

%p-auto {
  padding: auto;
}


//how to use
// @extend %m-1
// @extend %p-2
// @extend %m-1-neg
// @extend %p-2-neg
// spacing ends
// For width & height
@for $i from 1 through 10 {
  %h-#{calc($i * 10)} {
    height: calc($i * 10%);
  }

  %w-#{calc($i * 10)} {
    width: calc($i * 10%);
  }
}

// text transform start
%uppercase {
  text-transform: uppercase;
}

%lowercase {
  text-transform: lowercase;
}

%capitalize {
  text-transform: capitalize;
}

%line-through {
  text-decoration: line-through;
}

// text transform end
// text alignment start
%text-center {
  text-align: center;
}

%text-left {
  text-align: left;
}

%text-right {
  text-align: right;
}

// text alignment end
// display default start

@each $type in $display-types {
  %d-#{$type} {
    display: #{$type};
  }
}

// @extend %d-block;  
// @extend %d-inline; 
// @extend %d-none;  
// @extend %d-inline-block; 

// Flex classes start
%flex {
  display: flex;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

%flex-wrap {
  flex-wrap: wrap;
}

%flex-nowrap {
  flex-wrap: nowrap;
}

%flex-h-center {
  @include flex-config(flex, null, center, null);
}

%flex-v-center {
  @include flex-config(flex, null, null, center);
}

// Dynamically create flex classes for all combinations of justify and align
@each $justify, $justify-value in $flex-spacing {
  @each $align, $align-value in $flex-spacing {
    %flex#{$justify}#{$align} {
      @include flex-config(flex, null, #{$justify-value}, #{$align-value});
    }

    %flex-column#{$justify}#{$align} {
      @include flex-config(flex, column, #{$justify-value}, #{$align-value});
    }
  }
}

// Flex classes end
//how to use
// @extend %flex;
// @extend %flex-column;
// @extend %flex-c-fs;
// @extend %flex-column-fs-c;
%transition {
  transition: 0.3s;
}

// Overflow start
%contain-paint {
  contain: paint;
}


@each $name, $value in $overflows {
  %#{$name} {
    overflow: $value;
  }
}
