@use "../config/" as *;
.social {
    &-share {
        &.active {
            .social-share-wrap {
                @extend %flex;
            }
        }
        .share {
            &-label {
                @extend %d-none;
            }
        }
        .icon-b-close {
            &::before {
                @include icon(close);
                @extend %pure-white-900;
            }
        }
        &-wrap {
            @extend %d-none,%pos-tr;
        }
    }
    &-items {
        @extend %flex,%neutral-900-bg;
    }
    &-icon {
        width: 3rem;
        height: 3rem;
        aspect-ratio: 1/1;
    }
    &-wrap {
        @extend %flex;
        .close {
            width: 3rem;
            height: 3rem;
            @extend %neutral-900-bg;
        }
    }
}