@use "../config/" as *;

.waf-statslisting {
    @extend %py-5;
    .waf-head {
        width: max-content;
    }
    .tabs,.more-btn,.swiper-button-next,.swiper-button-prev {
        @extend %d-none;
    }
    .card {
        &-list {
            overflow-x: auto;
            @extend %flex, %gap-4;
        }
        &-item {
            flex-shrink: 0;
            flex-basis: 80%;
            &:only-child {
                flex-basis: 100%;
            }
        }
        &-head {
            height: rem(40);
            border-radius: $full-radius $full-radius 0 0;
            @extend %px-4, %flex-n-c, %primary-300-bg;
            .title {
                @extend %py-0, %secondary-800, %capitalize, %font-14-pb;
            }
        }
    }
    .team-item {
        height: rem(50);
        border-bottom: .1rem solid color(neutral,100);
        @extend %pure-white-900-bg, %px-4, %flex-n-c;
        .item-wrap {
            @extend %h-100, %w-100, %flex-n-c;
        }
        &.featured {
            @extend %primary-50-bg;
        }
    }
    .rank {
        flex-shrink: 0;
        width: rem(25);
        @extend %secondary-800, %font-12-pb;
    }
    .logo {
        @extend %pr-2;
        img {
            border: .1rem solid color(neutral, 50);
            width: rem(38);
            height: rem(22);
        }
    }
    .name {
        flex: 1;
        line-height: 1rem;
    }
    .fname,.lname {
        @extend %capitalize, %secondary-800;
    }
    .fname {
        @extend %font-12-pm;
    }
    .lname {
        @extend %font-12-pb;
    }
    .total-points {
        flex-shrink: 0;
        width: rem(50);
        @extend %text-right;
    }
    .points {
        @extend %secondary-800, %font-16-pb;
    }
    .label {
        @extend %d-none;
    }
    .featured {
        height: rem(80);
        .rank {
            width: rem(19);
        }
        .name {
            gap: .2rem;
            @extend %flex-column;
        }
        .fname,.lname {
            font-size: rem(14);
        }
        .logo {
            img {
                width: rem(44);
                height: rem(25);
            }
        }
    }
}