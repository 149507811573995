/* This config path is different from the website. This config path point to src > scss > amp >config */
@use "../config/" as *;

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font-primary, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* normal font weight for all texts, default line height for every text elements,  */
  font-weight: $body-font-weight;
  line-height: $default-line-height;
  @extend %neutral-50-bg;
  @extend %neutral-900;
}

button {
  border: none;
  @extend %neutral-900;
  @extend %transparent-bg;
  &:where(:not(:disabled)) {
    cursor: pointer;
  }
  &:where(:focus) {
    outline: none;
  }
}
