$colors: (
  primary: (
    //
    50: (45, 84%, 93%),
    //#FCF4DD
    100: (44, 93%, 84%),
    //#FCE7AF
    200: (45, 94%, 74%),
    //#FBDB7E
    300: (49, 97%, 54%),
    //#FCD116
    400: (45, 98%, 56%),
    //#FDC522
    500: (44, 100%, 50%),
    //#FEBC00
    600: (41, 100%, 50%),
    //#FFAF00
    700: (37, 100%, 49%),
    //#FC9B00
    800: (33, 98%, 50%),
    //#FD8C03
    900: (24, 98%, 50%),
    //#FC6803
    1000: (0, 0%, 15%) //
  ),
  secondary: (
    //
    50: (276, 21%, 71%),
    //#B7A4C4
    100: (255, 19%, 63%),
    //#978EB2
    200: (262, 16%, 53%),
    //#817399
    300: (261, 19%, 43%),
    //#685983
    400: (262, 23%, 37%),
    //#584873
    500: (261, 30%, 30%),
    //#453562
    600: (259, 34%, 24%),
    //#362953
    700: (257, 44%, 17%),
    //#23183E
    800: (269, 82%, 9%),
    //#160429
    900: (240, 100%, 4%),
    //#000016
    1000: (269, 83%, 25%),
    //#3F0B75
  ),
  neutral: (
    //
    50: (0, 0%, 96%),
    //#F5F5F5
    100: (0, 0%, 81%),
    //#CFCFCF
    200: (0, 0%, 79%),
    //#C9C9C9
    300: (0, 0%, 73%),
    //#BBBBBB
    400: (0, 0%, 60%),
    //#989898
    500: (0, 0%, 49%),
    //#7C7C7C
    600: (0, 0%, 33%),
    //#555555
    700: (0, 0%, 26%),
    //#424242
    800: (0, 0%, 15%),
    //#252525
    900: (0, 0%, 0%),
    //#000000
    1000: (0, 0%, 83%),
    //#D4D4D4
    1100: (0, 0%, 93%),
    //#ECECEC
    1200: (200, 4%, 44%),
    //#6C7275
    1300: (0, 0%, 7%),
    //#121212
  ),
  success: (
    //
    700: (112, 94%, 26%),
    //#148104
    800: (155, 97%, 24%),
    //#027A48
    900: (145, 75%, 41%),
    //#1AB85B
  ),
  warning: (
    //
    900: (40, 100%, 48%),
    //#F5A500
  ),
  error: (
    //
    800: (4, 74%, 49%),
    // #D92D20
    900: (360, 81%, 58%),
    //#EA3C3D
  ),
  pure-white: (
    //
    400: (192, 5%, 82%),
    //CFD3D4
    500: (0, 0%, 87%),
    //DEDEDE
    600: (0, 0%, 93%),
    //ECECEC
    700: (200, 4%, 44%),
    //#6C7275
    800: (206, 18%, 92%),
    //#E8ECEF
    900: (0, 0%, 100%),
    //#ffffff
  ),
  spider: (
    //
    1s: (269, 83%, 25%),
    // #3F0B75
    2s: (262, 16%, 53%),
    // #817399
    3s: (269, 82%, 9%),
    // #160429
    4s: (261, 19%, 43%),
    // #685983
    5s: (49, 97%, 54%),
    // #FCD116
  ),
  spidern: (
    //
    1s: (155, 97%, 24%),
    //  #027A48
    2s: (269, 83%, 25%),
    //  #3F0B75
    3s: (37, 100%, 49%),
    //  #FC9B00
    4s: (217, 57%, 51%),
    //  #3C73C9
    5s: (4, 74%, 49%),
    //  #D92D20
  ),
);
$flex-width: (10, 20, 25, 30, 33, 40, 50, 60, 70, 80, 100);
$font-detail: (
  PublicSans: (
    //
    pl:
      (
        family: "Public Sans",
        weight: 300,
        style: normal,
      ),
    pr: (
      family: "Public Sans",
      weight: 400,
      style: normal,
    ),
    pm: (
      family: "Public Sans",
      weight: 500,
      style: normal,
    ),
    psb: (
      family: "Public Sans",
      weight: 600,
      style: normal,
    ),
    pb: (
      family: "Public Sans",
      weight: 700,
      style: normal,
    ),
    peb: (
      family: "Public Sans",
      weight: 800,
      style: normal,
    ),
  ),
);
$directions: (
  t: -top,
  r: -right,
  b: -bottom,
  l: -left,
  x: -inline,
  y: -block,
);
$flex-spacing: (
  -sa: space-around,
  -sb: space-between,
  -se: space-evenly,
  -fe: flex-end,
  -fs: flex-start,
  -c: center,
  -s: stretch,
  -u: unset,
  -n: null
);

$zindex: (
  patterns: -1,
  overlay: 1,
  video-icon: 2,
  showcase: 3,
  icon: 4,
  timer: 5,
  modal: 26,
  modal-close: 21,
  modal-swiper: 22,
  modal-share: 22,
  filter: 22,
  header: 26,
  waf-select-box-wrap: 1,
  secondary-navbar: 21,
  marquee: 11,
  tooltip-data: 1,
  tooltip-data-icon: 2,
  cookies: 26,
  scrolltop: 25,
  ads: 1,
  sticky-ad: 20,
  dropdown: 1,
  user-profile-setting: 1,
  cart: 24,
  shop-filter: 25,
  login-wrap: 1,
  view-more: 1,
  region-box: 1,
  quotes: -1,
  squad-filter: 1,
  progress-bar: 1,
  progress-bar-holder: 0,
  graph-value: 1,
  manhattan-tooltip: 2,
  progress-bar-label: 1,
  default: 1,
);

$icons: (
  "drop-arrow": "\e88d",
  "arrow-left": "\e800",
  "arrow-right": "\e801",
  "circle-arrow-left": "\e85d",
  "circle-arrow-right": "\e85c",
  "arrow-up": "\e802",
  "arrow-down": "\e803",
  "chevron-left": "\e804",
  "chevron-right": "\e805",
  "chevron-up": "\e806",
  "chevron-down": "\e807",
  "fb": "\e808",
  "filter": "\e809",
  "link": "\e80a",
  "copylink": "\e80a",
  "play-store": "\e80b",
  "app-store": "\e80c",
  "tiktok": "\e80d",
  "whatsapp": "\e80e",
  "tw": "\e80f",
  "ig": "\e810",
  "linkedin": "\e811",
  "pen": "\e812",
  "close": "\e813",
  "call": "\e814",
  "play": "\e815",
  "ticket": "\e816",
  "info": "\e817",
  "user": "\e818",
  "clock": "\e819",
  "search": "\e81a",
  "checkmark": "\e819",
  "eye": "\e81c",
  "eye-close": "\e81d",
  "yt": "\f16a",
  "reset": "\e83c",
  "share": "\e81f",
  "trending": "\e841",
  "p-arrow-up": "\e90b",
  "p-arrow-down": "\e88a",
  "p-arrow-left": "\e84c",
  "p-arrow-right": "\e84b",
  "p-double-arrow-left": "\e849",
  "p-double-arrow-right": "\e84a",
  "gallery": "\e859",
  "video": "\e85a",
  "star": "\e868",
  "store": "\e82f",
  "bat": "\e82d",
  "ball": "\e82c",
  "repeat": "\e82e",
  "substitute": "\e830",
  "impact-player": "\e81b",
  "game-zone": "\e831",
  "user-1": "\e864",
  "stadium": "\e866",
  "timer": "\e867",
  "check-drop": "\e835",
  "setting": "\e986",
  "lock": "\e87a",
  "add": "\e888",
  "minus": "\e887",
  "close-circle": "\e846",
  "product-filter": "\e847",
  "region": "\e8cd",
  "link-redirect": "\e88e",
  "all-rounder": "\e86a",
  "wicke-keeper": "\e86b",
  "p-arror-right": "\e84b",
  "batter": "\e893",
  "p-share": "\e897",
  "p-info": "\e895",
  "arrow-forward": "\e89e",
  "arrow-backward": "\e89f",
  "live-timer": "\e8a0",
  "pt": "\e9f4",
  "patreon": '\e9f4',
  "instagram": "\e810",
  "twitter": "\e80f",
  "facebook": "\e808",
	"youtube": "\f16a",
);
$social: (
  "fb": "\e808",
  "yt": "\f16a",
  "tiktok": "\e80d",
  "whatsapp": "\e80e",
  "tw": "\e80f",
  "ig": "\e810",
  "linkedin": "\e811",
  "copylink": "\e80a",
  "pt": "\e9f4",
);
$socials: (
	"facebook": "\e808",
	"youtube": "\f16a",
	"tiktok": "\e80d",
	"whatsapp": "\e80e",
	"twitter": "\e80f",
	"instagram": "\e810",
	"linkedin": "\e811",
	"copylink": "\e80a",
	"patreon": '\e9f4'
);


$overflows: (
  hidden: hidden,
  overflow: auto,
  scroll: scroll,
  overflow-unset: unset,
  overflow-x-auto: auto,
  overflow-y-auto: auto,
  overflow-x-clip: clip,
  overflow-y-clip: clip
);

$positions: (y-center, x-center, center, tl, tr, bl, br);

$border-radii: (
  full-radius: 0.5rem,
  half-radius: 0.25rem,
  circle-radius: 50%,
  rounded-radius: 50vmax
);

$display-types: inline, none, block, inline-block;

// short code map

$weathers: (
	sunny,
	fog,
	clouds,
	rain,
	snow,
	moon,
);