@use "amp-map" as *;
// base variables
$content-width: var(--wiw);
$base-space: 0.25rem;
$full-radius: 0.5rem;
$half-radius: calc($full-radius / 2);
$default-line-height: 1.6;
$body-font-size: 0.875rem;
$body-font-weight: 400;
$header-height: 4.063rem;
$social-height: 3.12rem;
$secondary-menu-height: 8rem;
$sticky-ad-height: 5rem;
// maximum count variable generation
$max-space-count: 25;

// font variables
$font-primary: "Public Sans", sans-serif;
$font-icon: "waf-font-icon";
// version variables
$image-version: "23.31";
$font-version: "80815819";
$logo-ratio: 0.21941;
$logo-width: 6.4375rem;
$logo-height: calc($logo-width * $logo-ratio);
//z-index values
$z-patterns: -1;
$z-overlay: 1;
$z-video-icon: 2;
$z-showcase: 3;
$z-icon: 4;
$z-timer: 5;
$z-modal: 26;
$z-modal-close: 21;
$z-modal-swiper: 22;
$z-modal-share: 22;
$z-filter: 22;
$z-header: 26;
$z-waf-select-box-wrap: 1;
$z-secondary-navbar: 21;
$z-marquee: 11;
$z-tooltip-data: 1;
$z-tooltip-data-icon: 2;
$z-cookies: 26;
$z-scrolltop: 25;
$z-ads: 1;
$z-sticky-ad: 20;
$z-dropdown: 1;
$z-user-profile-setting: 1;
$z-cart: 24;
$z-shop-filter: 25;
$z-login-wrap: 1;
$z-view-more: 1;
$z-region-box: 1;
$z-quotes: -1;
$z-squad-filter: 1;
$z-progress-bar: 1;
$z-progress-bar-holder: 0;
$z-graph-value: 1;
$z-manhattan-tooltip: 2;
$z-progress-bar-label: 1;
$z-default: 1;
// root variables that need to be updated via Javascript
:root {
  --vh: 1vh;
  --vw: 1vw;
  --wih: calc(var(--vh, 1vh) * 100);
  --wiw: calc(var(--vw, 1vw) * 100);
  --cmw: 100%;
  --cws: calc((var(--wiw) - var(--cmw)) / 2);
}
