@use "amp-map" as *;
@use "amp-variables" as *;

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin flex-config(
  $display: flex,
  $flex-direction: null,
  $justify-content: null,
  $align-items: null
) {
  display: $display;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}
// how to use
// @include flex-config(); // just flex
// @include flex-config(flex,column);
// @include flex-config(flex,null,center,center);

@mixin position-combo($combo: null, $x: null, $y: null, $position: absolute) {
  position: $position;

  $positions: (
    "tl": (top: 0, left: 0),
    "tr": (top: 0, right: 0),
    "bl": (bottom: 0, left: 0),
    "br": (bottom: 0, right: 0),
    "inset": (inset: 0),
    "center": (top: 50%, left: 50%, translate: -50% -50%),
    "y-center": (top: 50%, translate: 0 -50%),
    "x-center": (left: 50%, translate: -50% 0)
  );

  @if map-has-key($positions, $combo) {
    @each $prop, $value in map-get($positions, $combo) {
      #{$prop}: $value;
    }
  }

  // Handling dynamic x and y values for x-center & y-center
  @if $combo == "y-center" {
    right: $x;
    left: $y;
  } @else if $combo == "x-center" {
    top: $x;
    bottom: $y;
  }
}

// @include position-combo(tl); // top == left == 0
// @include position-combo(center);
@mixin position(
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $position: absolute
) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  position: $position;
}
// how to use
// @include position(1rem,2rem,3rem,4rem); // top right bottom left
// @include position(1rem,2rem,3rem,4rem,fixed); // top right bottom left fixed


@mixin bg($color, $opacity: 10) {
  $color-hsla: hsl(var(--hsl-#{$color}) / calc($opacity * 0.1));
  background-color: $color-hsla;
}
@mixin color($color, $opacity: 10) {
  $color-hsla: hsl(var(--hsl-#{$color}) / calc($opacity * 0.1));
  color: $color-hsla;
}
// how to use
// @include bg(white,7) // color with opacity
// @include bg(black) // solid color

@mixin truncate-text($line-number, $height: auto, $update: false) {
  @if $update ==false {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  -webkit-line-clamp: $line-number;
  @if $height !=auto {
    height: $height * 0.1rem;
  }
}
// how to use
// @include truncate-text(3);
// @include truncate-text(3, 12rem); // Set fixed height
// @include truncate-text(4, 18rem, true); // Update truncate parameters in mixin

// change map as per project
@mixin icon($name, $size: 8) {
  $icon: map-get($icons, $name);
  font-family: $font-icon;
  @if $icon !=null {
    content: $icon;
    font-size: $size * 0.1rem;
    line-height: 1;
  } @else {
    @error "Icon '#{$name}' not found in $icons map.";
  }
}

// how to use
// @include icon(arrow-down)
// @include icon(arrow-down,14,null)

@mixin background($bg-color: null, $bg-Img: null, $bg-Props...) {
  @if $bg-Img ==null {
    background: $bg-Props;
  } @else if $bg-color ==null {
    background: url("/static-assets/images/#{$bg-Img}?v=#{$image-version}")
      $bg-Props;
  } @else {
    background: $bg-color
      url("/static-assets/images/#{$bg-Img}?v=#{$image-version}")
      $bg-Props;
  }
}
// how to use
// background(var(--neutral-100), "cssimages/svg/vector.svg", center / contain no-repeat);




// short code mixin
@mixin separator(
  $space: 100%,
  $type: x,
  $thickness: 0.1rem,
  $colorVal: neutral-900,
  $opacity: 2
) {
  $color: hsl(var(--hsl-#{$colorVal}) / #{$opacity * 0.1});
  // Set common styles for the separator
  // Horizontal separator
  @if $type ==y {
    // Default styles
    position: relative;
    &::after {
      content: "";
      width: $thickness;
      height: $space;
      background-color: $color;
      @include position-combo(y-center, 0);
    }
  }
  // Vertical separator
  @else if $type ==x {
    position: relative;
    &::after {
      content: "";
      width: $space;
      height: $thickness;
      background-color: $color;
      @include position-combo(x-center, null, 0);
    }
  }
}

@mixin grid($count, $gap: var(--space-2), $update: false) {
  @if $update ==false {
    display: grid;
  }
  gap: $gap;
  grid-template-columns: repeat($count, minmax(0, 1fr));
}