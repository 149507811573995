@use "../config/" as *;

.shdr {
  @extend %w-100;
}
.l {
  height: 0.18rem;
  transition: 300ms transform;
  @extend %rounded-radius, %secondary-800-bg, %mb-2, %w-100;

  &b {
    @extend %mb-0;
  }
  &t,
  &b {
    @extend %w-60, %ml-auto;
  }
}
.bham {
  width: 4.063rem;
  height: $header-height;
  padding-bottom: env(safe-area-inset-bottom);
  position: relative;
  @extend %primary-300-bg, %px-4, %flex-column-c-c;
 
  &[aria-expanded="true"] {
    &~.mnwrp {
      right: 0;
    }
    .l {
      &t {
        @extend %w-100;
        transform: translateY(0.62rem) rotate(45deg);
      }
      &b {
        @extend %w-100;
        transform: translateY(-0.75rem) rotate(-45deg);
      }
      &m {
        opacity: 0;
      }
    }
  }
}

.shdr-wrp {
  bottom: 0;
  height: $header-height;
  z-index: $z-header;
  box-shadow: 0rem -0.25rem 0.25rem 0rem color(neutral, 900, 1);
  @extend %fixed, %w-100, %pure-white-900-bg, %flex-sb-c, %h-100;
}

.um {
  isolation: isolate;
}

.slgo {
  @extend %mr-auto;

  &l {
    width: $logo-width;
    display: block;
    height: $logo-height;
    margin-bottom: env(safe-area-inset-bottom);
    transition: 300ms height, 300ms width;
    @include background(null, "logo-blue.png", center/contain no-repeat);
  }

  &t {
    @extend %font-0;
  }
}

.btnl {
  width: 1.75rem;
  height: 1.75rem;
  gap: 0;
  @extend %p-3, %flex-n-c, %gap-2, %p-0, %flex-c-c, %secondary-900-bg, %circle-radius;

  &[aria-expanded="true"] {
    &~.lsw {
      opacity: 1;
      pointer-events: all;

      .lbtn {
        height: 1.875rem;
      }
    }

    .btnl-tx {
      @extend %font-0;
    }

    &::before {
      @extend %pure-white-900;
      @include icon(close, 8);
    }
  }
}

.uimg {
  flex-shrink: 0;
  width: 1.75rem;
}

.btnl-tx {
  @extend %flex, %pure-white-900;
}

.lsw {
  position: absolute;
  bottom: 100%;
  right: 0;
  isolation: isolate;
  box-shadow: 0.0625rem 0.0625rem 0.3125rem -0.125rem color(secondary, 800, 5);
  opacity: 0;
  pointer-events: none;
  transition: 300ms opacity;
  border-radius: $half-radius $half-radius 0 0;
  @extend %neutral-800-bg-9, %p-4, %gap-3, %flex, %w-100;
}

.lbtn-tx {
  display: block;
  @extend %pure-white-900;
}

.lbtn {
  height: 0rem;
  transition: 300ms height;
  flex: 1;
  border: 0.0625rem solid color(pure-white, 900);
  @extend %hidden, %half-radius, %flex-c-c, %w-100, %text-center, %px-3;

  &.active {
    border: 0.0625rem solid color(primary, 300);
    @extend %primary-300-bg;

    .lbtn-tx {
      @extend %secondary-900;
    }
  }
}

.hl {
  flex: 1;
  @extend %px-3, %flex-n-c;
}

.prm-llbl {
  @extend %d-none;
}

.prm{
  &-a{
    width: 1.625rem;
    height: 1.625rem;
    transition: 300ms background-color;
    @extend %d-block, %circle-radius, %hidden, %primary-200-bg, %flex-c-c, %secondary-800;
  
    &:hover {
      @extend %primary-600-bg;
    }
    @each $key, $value in $social {
      &-#{$key}::before {
        @include icon($key);
      }
    }
  }
  &-t {
    @extend %font-0;
  }
  &-l {
    @extend %flex-c-n, %gap-4;

    &wrp {
      box-shadow: 0rem -0.25rem 0.25rem 0rem color(neutral, 900, 1);
      height: $social-height;
      @extend %py-3, %primary-100-bg;
    }
  }
}

.mnwrp {
  top: 0;
  right: -100%;
  height: calc(100dvh - $header-height);
  max-width: 19.375rem;
  transition: 300ms right ease-in-out;
  z-index: $z-patterns;
  @extend %fixed, %primary-50-bg, %w-100;

  ul {
    list-style-type: none;
    @extend %m-0, %p-0;
  }

  .snav {
    height: calc(100% - $social-height);
    @extend %overflow-y-auto;
  }
  .nlst {
    @extend %px-4, %pt-6;
  }
  .n{
    &itm-active{
      @extend %relative;

      &::after {
        content: "";
        height: 0.0625rem;
        background-image: linear-gradient(to right, #160429, transparent);
        @extend %pos-bl, %w-100;
      }

      >.nva {
        line-height: 1;
        @extend %font-16-pb;
      }
    }
    &va{
      line-height: 1;
      @extend %font-12-pm, %neutral-800, %d-block, %p-3-0, %uppercase, %w-100, %text-left;
    }
    &itm-sm{
      >.nva {
        @extend %flex-sb-c, %gap-2;

        &::after {
          transition: 0.3s rotate;
          @include icon(p-arrow-down, 12);
        }

        &[aria-expanded="true"]::after {
            rotate: 180deg;
        }
      }
    }
  }

  .sbm,
  .sbm-sbn {
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    @extend %hidden;
  }

  .nva[aria-expanded="true"]+.sbm,
  .nva[aria-expanded="true"]+.sbm-sbn {
    max-height: 10rem;
  }

  .sbm {
    &-i .nva {
        white-space: nowrap;
        @extend %w-100;
      }

    &-i:last-child .sbn-i:last-child {
        border-bottom: 0;
      }
  }

  .sbn {
    >.nva {
      @extend %flex-sb-c, %gap-2;

      &::after {
        transition: 0.3s rotate;
        @include icon(add, 12);
      }

      &[aria-expanded="true"] {
        &::after {
          rotate: 180deg;
          @include icon(minus, 12);
        }
      }
    }

    &-i {
      .nva {
        @extend %neutral-600, %font-12-pr;
      }

      &:last-child {
        border-bottom: 0.0625rem solid color(neutral, 600);
      }
    }
  }
}
.promo {
  &-anchor {
    width: 1.6rem;
    height: 1.6rem;
    transition: background-color 300ms;
    @extend %d-block, %circle-radius, %hidden, %primary-200-bg, %flex-c-c, %secondary-800;
    &:hover {
      @extend %primary-600-bg;
    }
    @each $key, $value in $socials {
      &-#{$key}::before {
        @include icon($key);
      }
    }
    .promo-text {
      @extend %font-0;
    }
  }  
  &-list {
    @extend %flex-c-n, %gap-4;
    &-wrap {
      height: $social-height;
      box-shadow: 0rem -0.4rem 0.4rem 0rem clr(neutral-900, 1);
      @extend %py-3, %primary-100-bg;
    }
  }
}