// This config path is different from the website. This config path point to src > scss > amp >config
@use "../config/" as *;

*{
  box-sizing: border-box;
}

html {
  @extend %w-100;
}

.dsk {
  @extend %d-none;
}

.mob {
  @extend %d-block;
}

.mwrp {
  padding-bottom: $header-height;
}

a {
  text-decoration: none;
}

.swiper-button-prev,.swiper-button-next,.swiper-pagination,.swiper-scrollbar{
  @extend %d-none;
}

