// Imports
@use "./config/" as *;
@forward "./core/";
@forward "./detail/amp-masthead";
@forward "./detail/amp-standing";
@forward "./detail/amp-quote";
@forward "./detail/amp-statslisting";
@forward "./detail/amp-statsdetail";
@forward "./detail/amp-listing";
@forward "./detail/amp-share";

.overflow-window {
  overflow: hidden;
  aspect-ratio: 4 / 3;
}

//   amp-img img {
//     object-fit: cover;
//   }
// Social platforms and icons
$social-platforms: (
  "facebook": "\e808",
  "whatsapp": "\e80e",
  "twitter": "\e80f",
  "copylink": "\e80a",
);

@each $key, $value in $social-platforms {
    .amp-social-share-#{$key}::before {
        content: "#{$value}";
        font: 1rem $font-icon;
    }
    .icon-b-#{$key} {
        &::before {
            content: $value;
            font: 1rem $font-icon;
        }
    }
}

// WAF detail styles
.waf-amp {
  @extend %p-4, %pure-white-900-bg;
  .social-wrap {
    @extend %flex;
    @extend %gap-3;
  }
  amp-social-share {
    @extend %flex-c-c, %circle-radius, %primary-300-bg;
    background-image: none;
  }

  p,
  li {
    line-height: 1.3;
    word-wrap: break-word;
    @extend %neutral-600, %pb-7, %font-16-pr, %m-0;

    &:empty {
      @extend %d-none;
    }

    strong {
      font-weight: 700;
    }
  }

  a {
    cursor: pointer;
    &:not(.meta) {
      @extend %primary-700;
    }

    &:hover {
      text-decoration: underline;
      @extend %primary-700;
    }
  }

  ol,
  ul {
    @extend %pl-4;

    li {
      @extend %mb-0;
    }
  }
}

// Article Styles
.article-head {
  @extend %relative;
  .meta {
    @extend %py-0, %neutral-500, %font-10-pm;
  }
  .meta-category {
    font-size: 0.75rem;
    @extend %uppercase, %pos-tl, %secondary-600;
  }
}

.title {
  @extend %m-0, %py-6, %font-24-pb, %neutral-900;
}

.article-meta {
  @extend %mb-4, %flex-n-c;
}

.meta-read-time::after {
  @extend %d-none;
}

.meta {
  &:not(:last-child, :nth-last-child(2)) {
    &::after {
      content: "|";
      @extend %mx-2;
    }
  }

  &-author {
    @extend %my-0;

    &:empty {
      @extend %d-none;
    }
  }
}

// Social share styles
.social-share-wrap {
  @extend %flex, %gap-3;
}

.share-icon {
  order: 1;
  @extend %d-none;

  &::before {
    @include icon(share);
  }
}

// Article body and media
.article-featured-media {
  @extend %my-4;

  .img-box {
    @extend %m-0;
  }
  .caption {
    font-size: 0.65rem;
    @extend %pb-0;
  }
}

// Intro styles
.drop-cap-intro,
.intro {
  @extend %font-16-pb;

  &:first-letter {
    float: left;
    font-size: 4.8rem;
    line-height: 1;
  }
}

// Detail title and header styles
.detail-title {
  @extend %neutral-800, %my-0, %pb-4, %font-18-pb;
}

h2,
h3,
h4 {
  line-height: 1.2;
  @extend %pb-4;
}

h3,
h4 {
  font-weight: 400;
}

h2 {
  font-size: 1.5rem;
  font-weight: 500;
  @extend %m-0;
}

h3 {
  font-size: 2.2rem;
}

h4 {
  font-size: 1.8rem;
}

// Align classes
.align-center,
.align-left,
.align-right {
  &.img-box {
    @extend %mb-4;
  }
}

.img-box {
  @extend %neutral-50-bg-5, %half-radius;
}

// Caption styles
figcaption {
  font-weight: 500;
  @extend %neutral-800, %px-2, %py-1;

  &:empty {
    @extend %d-none;
  }
}

// Table styles
.table-responsive {
  @extend %d-block, %w-100, %overflow-x-auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  font-size: 0.87rem;
  min-width: 100%;
  border-collapse: collapse;
  border: 0.1rem solid color(neutral, 700, 3);
  @extend %overflow-unset;
  &:has(> .table-left) {
    @extend %flex;
  }
  thead {
    @extend %primary-100-bg-8;
  }

  tr p {
    @extend %pb-0;
  }

  th,
  td {
    border: 0.1rem solid color(neutral, 700, 3);
    @extend %capitalize, %text-left, %p-2;
  }

  tr {
    @extend %relative;
  }
  &-left {
    @extend %w-40;
  }
  &-right {
    @extend %overflow;
    @extend %w-60;
  }
  &-row {
    border-bottom: 0.1rem solid color(neutral, 50);
    @extend %w-100;
    @extend %flex;
    &-wrap {
      @extend %w-100;
      .row-head {
        @extend %flex;
      }
    }
  }
  &-head {
    @extend %primary-100-bg-8;
    .table-data {
      height: var(--_table-head-data-height, 2.6rem);
    }
  }

  &-data {
    flex-shrink: 1;
    height: var(--_table-data-height, 2.6rem);
    @extend %w-100;
    @extend %text-center;
    @extend %flex-c-c;
  }
  .text {
    @extend %font-12-pm;
    @extend %uppercase;
  }
  .form-guide {
    @extend %d-none;
  }
}

// iFrame styles
iframe:not(.waf-ad iframe) {
  @extend %w-100;
}

// Advertisement styles
.waf-ad {
  width: max-content;
  z-index: $z-ads;
  @extend %w-100;
}

// Article body customization
.article-body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    > *,
    > * > * {
      font-size: inherit;
      font-weight: inherit;
    }
  }

  //   .twitter-tweet,
  //   .instagram-media {
  //     margin-inline: auto !important;
  //   }

  figure {
    &.img-box {
      margin: auto;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.article-commentbox,
.views-section {
  @extend %d-none;
}
// WAF Shortcode styles
:is(.waf-shortcode) {
  @extend %half-radius;
  @extend %mb-4;
  p,
  .text,
  li {
    padding-bottom: 0;
  }
  .waf-head {
    @extend %mb-4;
    p,
    .text,
    li {
        padding-bottom: 0;
    }
    .waf-head {
        @extend %mb-4;
        .title {
            font-weight: 700;
            @extend %neutral-800;
            @extend %my-0;
            @extend %pt-0;
            @extend %uppercase;
        }
    }
    &:not(.waf-quote,.waf-cricketscorecard) {
        @extend %p-4, %neutral-50-bg;
        
    .title {
      font-weight: 700;
      @extend %neutral-800;
      @extend %my-0;
      @extend %pt-0;
      @extend %uppercase;
    }
  }
  &:not(.waf-quote) {
    @extend %p-4, %neutral-50-bg;
  }
}
}
